<template>
    <div class="content pad-bottom--vxl">
      <div class="grid-container pad-top">
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pad-top">
            <h1 class="text-white large-heading pad-bottom">Prospective Consultants Academy 2022</h1>
          </div>
        </div>
        <div class="grid-x grid-padding-x">
          <div class="cell small-12 pad bg--white">
            <p>
              Thank you for registering to take part in the Prospective Consultant Academy (PCa) 2022! This year’s course includes two online modules, which aim to provide foundational knowledge on the topics included in the masterclass meeting. We recommend completing the modules ahead of the masterclass to support your learning.
            </p>
            <p>
              Each module will take approximately 30 minutes to complete. A short set of assessment questions will be available at the end of the modules to check your learning. Click on a module below to get started.
            </p>
            <p>
              <router-link to="/module-one">
                <button class="button">
                  What happens when things go wrong?
                </button>
              </router-link>
            </p>
            <p>
              <router-link to="/module-two">
                <button class="button">
                  Navigating the NHS
                </button>
              </router-link>
            </p>
            <p>
              <router-link to="/agenda">
                <button class="button">
                  Masterclass 2022 Agenda
                </button>
              </router-link>
            </p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Home',
  };
  </script>
  